import { React, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getStats } from "../src/api/api";

import "./styles/App.scss";

import Standings from "./pages/Standings/Standings.page";
import Header from "./components/Header/Header.component";
import Footer from "./components/Footer/Footer.component";
import Draft from "./pages/Draft/Draft.page";

import SignIn from "./pages/SignIn/SignIn.page";
import { findTeam, findTeamLogo, sortStandings } from "./utils/helpers";
import firebase from "./firebase/firebase.utils";
import { auth, createUserProfileDocument } from "./firebase/firebase.utils";

function App() {
  const seasonStartDay = process.env.REACT_APP_SEASON_OPENS;
  const draftDay = 1725559530000;
  const oneWeek = 604800000;

  const [lastUpdatedOn, setLastUpdatedOn] = useState("");

  const [allTeams, setAllTeams] = useState([]);

  const [players, setPlayers] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [adminUser, setAdminUser] = useState(null);
  const [picks, setPicks] = useState([]);

  const refPicks = firebase.firestore().collection("players");

  const current = Date.now();
  const startDay = parseInt(seasonStartDay);
  const seasonInProgress = current >= startDay;

  const diff = current - startDay;
  const weeksPassed = Math.floor(diff / oneWeek);
  const weekInSeason = weeksPassed + 1;

  // Retrieves players' picks from DB
  useEffect(() => {
    const getPicks = refPicks.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
        items.sort((a, b) => {
          return parseInt(a.draftOrder) - parseInt(b.draftOrder);
        });
      });

      setPicks(items);
    });

    return getPicks;
    // eslint-disable-next-line
  }, []);

  // Retrieves NFL league info from API
  useEffect(() => {
    async function fetchStats() {
      const response = await getStats();
      const { teams, lastUpdatedOn } = response;
      setLastUpdatedOn(lastUpdatedOn);
      setAllTeams(teams);
    }

    fetchStats();
    // eslint-disable-next-line
  }, []);

  // Sets sorted list of teams drafted by player
  useEffect(() => {
    if (picks?.length > 0 && allTeams?.length > 0) {
      const playerList = [];

      for (const player of picks) {
        const draftedTeam = [];

        for (const team of player?.teamList) {
          draftedTeam.push(findTeam(allTeams, team, player.id));
        }
        const teamLogo = findTeamLogo(allTeams, player.mainLogo);

        playerList.push({
          id: player.id,
          logo: teamLogo,
          teams: draftedTeam,
        });
      }
      setPlayers(playerList);
      setSortedList(sortStandings(playerList));
    }

    // eslint-disable-next-line
  }, [picks, allTeams]);

  // for loging in

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot((snapShot) => {
          setAdminUser({
            adminUser: {
              id: snapShot.id,
              ...snapShot.data(),
            },
          });
        });
      }

      setAdminUser({ adminUser: userAuth });
    });
    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  return (
    <div className='App'>
      <Header adminUser={adminUser} />
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Standings
              players={players}
              sortedList={sortedList}
              seasonInProgress={seasonInProgress}
              adminUser={adminUser}
              lastUpdatedOn={lastUpdatedOn}
              teamsByOwner={picks}
              weekInSeason={weekInSeason}
            />
          )}
        />
        <Route
          path='/draft'
          render={() => (
            <Draft
              allTeams={allTeams}
              adminUser={adminUser}
              scheduled={draftDay}
            />
          )}
        />
        {adminUser?.adminUser ? (
          <Redirect to='/draft' />
        ) : (
          <Route path='/signin' component={SignIn} />
        )}
        ;
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
