import React from "react";
import Menu from "../Menu/Menu.component";
// import CustomButton from "../CustomButton/CustomButton.component";
// import { signInWithGoogle } from "../../firebase/firebase.utils";
import logo from "../../assets/images/Biohazard_symbol.png";

export default function Header({ adminUser }) {
  return (
    <header>
      <section className='main'>
        <div className='main__title-wrapper'>
          <img src={logo} alt='bio-hazzard symbol' />
          <h1 className='main__title'>Plague Pool</h1>
          <h3 className='main__subtitle'>&mdash; 2024 &mdash;</h3>
        </div>
        <div className='main__menu-wrapper'>
          <Menu adminUser={adminUser} />
        </div>
        {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
          Sign In With Google
        </CustomButton> */}
      </section>
    </header>
  );
}
