import axios from "axios";
import base64 from "base-64";
import utf8 from "utf8";

const myPwd = process.env.REACT_APP_PWD_API;
const myAPI = process.env.REACT_APP_MY_API_KEY;
const apiToken = utf8.encode(`${myAPI}:${myPwd}`);
const encoded = base64.encode(apiToken);
const AuthStr = `Basic ${encoded}`;
const season = process.env.REACT_APP_SEASON;

export const getStats = async () => {
  const response = await axios.get(
    `https://api.mysportsfeeds.com/v2.1/pull/nfl/${season}/standings.json`,
    { headers: { Authorization: AuthStr } }
  );

  return response.data;
};

export const getSchedules = async () => {
  const response = await axios.get(
    `https://api.mysportsfeeds.com/v2.1/pull/nfl/${season}/games.json`,
    { headers: { Authorization: AuthStr } }
  );
  const { games } = response.data;
  return games;
};

export const getWeeklySchedules = async (week) => {
  const response = await axios.get(
    `https://api.mysportsfeeds.com/v2.1/pull/nfl/${season}/week/${week}/games.json`,
    { headers: { Authorization: AuthStr } }
  );
  const { games } = response.data;

  return games;
};
