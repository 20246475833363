import React from "react";
import CustomButton from "../CustomButton/CustomButton.component";
import firebase from "../../firebase/firebase.utils";

function DraftOrder({ players }) {
  const refPlayers = firebase.firestore().collection("players");

  function setDraftOrder(event) {
    event.preventDefault();
    const playerOrders = event.target;

    for (let player of playerOrders) {
      if (player.id !== "") {
        if (player.value === "") {
          continue;
        }
        let playerToUpdate = refPlayers.doc(player.id);
        playerToUpdate.update({
          draftOrder: player.value,
        });
      }
    }
  }

  return (
    <div className='draft-order'>
      <h3>Draft Order</h3>
      <form
        onSubmit={(event) => {
          setDraftOrder(event);
        }}
      >
        {players.map((player) => (
          <div key={player.id} className='draft-order__list-item'>
            <label htmlFor={player.id}>{player.id}</label>
            <input
              className='orderInputs'
              type='number'
              id={player.docId}
              name={player.id}
              min='1'
              max='8'
              placeholder={player.draftOrder}
            />
          </div>
        ))}

        <CustomButton type='submit'>Set Order</CustomButton>
      </form>
    </div>
  );
}
export default DraftOrder;
