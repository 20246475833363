import React from "react";
import CustomButton from "../CustomButton/CustomButton.component";
import firebase from "../../firebase/firebase.utils";

function ResetBoard() {
  function setPlayersToDefault() {
    const refPlayers = firebase.firestore().collection("players");

    refPlayers
      .get()
      .then((allDocs) => {
        allDocs.forEach((doc) => {
          refPlayers.doc(doc.id).update({
            teamList: [],
            limitReached: false,
          });
        });
      })
      .catch((error) => {
        console.log("Error getting doc: ", error);
      });
  }

  function setTeamsToDefault() {
    const refTeams = firebase.firestore().collection("teams");

    refTeams
      .get()
      .then((allDocs) => {
        allDocs.forEach((doc) => {
          refTeams.doc(doc.id).update({
            available: true,
          });
        });
      })
      .catch((error) => {
        console.log("Error getting doc: ", error);
      });
  }

  async function resetDefaultDraftSettings() {
    const clearLists = window.confirm(
      "You are about to reset the lists. Are you sure?"
    );
    const resetingBoardToDefault = clearLists ? true : false;

    if (resetingBoardToDefault) {
      setPlayersToDefault();
      setTeamsToDefault();
    }
  }

  return (
    <div className="draft-reset">
      <h3>Clear Team Lists</h3>

      <CustomButton type="submit" onClick={() => resetDefaultDraftSettings({})}>
        Reset Board
      </CustomButton>
      <p>
        This should only be used at the very begining of draft to clear last
        year's settings.
      </p>
    </div>
  );
}
export default ResetBoard;
