import React, { useState } from "react";
import FormInput from "../../components/FormInput/FormInput.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
// import SignUp from "../../components/SignUp/SignUp.component";
import { auth } from "../../firebase/firebase.utils";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "email") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  return (
    <div className='sign-in'>
      <div className='sign-in__main'>
        <h2>Sign in with your email and password</h2>

        <form onSubmit={handleSubmit}>
          <FormInput
            name='email'
            type='email'
            handleChange={handleChange}
            value={email}
            label='email'
            required
          />

          <FormInput
            name='password'
            type='password'
            value={password}
            handleChange={handleChange}
            label='password'
            required
          />
          <div className='buttons'>
            <CustomButton type='submit'>Sign In</CustomButton>
          </div>
        </form>
        {/* <SignUp /> */}
      </div>
    </div>
  );
}
export default SignIn;
