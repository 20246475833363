import { React, useState, useEffect } from "react";
import DraftAdmin from "../../components/DraftAdmin/DraftAdmin.component";
import DraftView from "../../components/DraftView/DraftView.component";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner.component";
import firebase from "../../firebase/firebase.utils";
// import loadingImg from "../../assets/images/loading.gif";

function Draft({ adminUser, allTeams, scheduled, dayOfDraft }) {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const refPlayers = firebase.firestore().collection("players");
  const refTeams = firebase.firestore().collection("teams");

  const draftDay = new Date(scheduled);
  const currentDay = Math.floor(new Date().getTime() / 1000.0); //epoch time
  const openDraft = currentDay <= scheduled;

  const month = draftDay.toLocaleString("en-US", { month: "long" });
  const day = draftDay.toLocaleString("en-US", { day: "numeric" });
  const year = draftDay.toLocaleString("en-US", { year: "numeric" });

  const fullDate = `${month} ${day}, ${year}`;

  useEffect(() => {
    setLoading(true);
    const getPlayers = refPlayers.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
        items.sort((a, b) => {
          return parseInt(a.draftOrder) - parseInt(b.draftOrder);
        });
      });
      setPlayers(items);
      setLoading(false);
    });

    return getPlayers;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    const getTeams = refTeams.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
        items.sort((a, b) => {
          return a.city.toLowerCase() < b.city.toLowerCase() ? -1 : 1;
        });
      });
      setTeams(items);
      setLoading(false);
    });

    return getTeams;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      setLoading(false);
    }
  }, [teams]);

  async function recordTeamSelection(event) {
    const { name, value } = event.target;

    const addToPlayerTeamList = refPlayers.doc(value);
    const teamNoLongerAvailable = refTeams.where("id", "==", parseInt(name));

    // Find team selected and make unavailable
    // Change property 'available' to false and update document
    let teamSelected;

    teamNoLongerAvailable
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const teamDetails = doc.data();

          teamSelected = teamDetails.docId;

          refTeams.doc(teamSelected).update({
            available: false,
          });
        });
      })
      .catch((error) => {
        console.log("Error getting doc: ", error);
      });

    // Used for swaping of team selections
    // Check if team id is already present in any other player teamList
    // Remove if detected, and reset limitReached to false in case player had readched limit
    const removeFromDoc = await refPlayers
      .where("teamList", "array-contains", parseInt(name))
      .get();

    removeFromDoc.forEach((doc) => {
      refPlayers
        .get()
        .then(() => {
          refPlayers.doc(doc.id).update({
            teamList: firebase.firestore.FieldValue.arrayRemove(parseInt(name)),
            limitReached: false,
          });
        })
        .catch((error) => {
          console.log("Error getting doc: ", error);
        });
    });

    // For adding team selection to player teamList
    // Get current length of teamList array to use for validation if player has not maxed-out at 4 teams selected already
    // If maxed - do nothing
    // If this selection is the fourth and final selection, add to teamList then change limitReached property to true
    // If this is pick 1-3 then simply add to teamList
    let lengthOfArray = 0;

    await addToPlayerTeamList
      .get()
      .then((doc) => {
        const playerAddingDetails = doc.data();
        lengthOfArray = playerAddingDetails.teamList.length;
      })
      .catch((error) => {
        console.log("Error getting doc: ", error);
      });

    if (lengthOfArray > 3) {
      return;
    } else if (lengthOfArray > 2) {
      addToPlayerTeamList.update({
        teamList: firebase.firestore.FieldValue.arrayUnion(parseInt(name)),
        limitReached: true,
      });
    } else {
      addToPlayerTeamList.update({
        teamList: firebase.firestore.FieldValue.arrayUnion(parseInt(name)),
      });
    }
  }

  return (
    <div className='draft-page'>
      <section>
        {openDraft ? (
          <h1>Draft Results {year}!</h1>
        ) : (
          <h1>Draft Day is {fullDate}</h1>
        )}

        {adminUser?.adminUser ? (
          <DraftAdmin
            displayTeams={allTeams}
            selection={recordTeamSelection}
            draftManager={players}
            availableTeams={teams}
          />
        ) : null}
        {loading ? (
          <div className='loader-height'>
            <LoadingSpinner />
          </div>
        ) : (
          <DraftView
            availableTeams={teams}
            players={players}
            allTeams={allTeams}
          />
        )}
      </section>
    </div>
  );
}
export default Draft;
