import React from "react";

export default function Footer() {
  return (
    <footer>
      <p>
        This site is for personal use only and is in no way affilated with the
        National Football League or its' teams.
        <br />
        That being said, please don't piss in our cereal for using your
        registered logos. Our little site is just a way for us to enjoy the
        season.
        <br />
        Cheers!
      </p>
    </footer>
  );
}
