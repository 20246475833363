import React from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";

export default function Menu({ adminUser }) {
  return (
    <div className='main-menu'>
      <NavLink exact className='main-menu__option' to='/'>
        Standings
      </NavLink>
      <NavLink className='main-menu__option' to='/draft'>
        Draft
      </NavLink>

      {adminUser?.adminUser ? (
        <div
          className='main-menu__option sign-out'
          onClick={() => auth.signOut()}
        >
          Sign Out
        </div>
      ) : (
        <NavLink className='main-menu__option' to='/signin'>
          Sign In
        </NavLink>
      )}
    </div>
  );
}
